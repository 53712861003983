import { SET_BREADCRUMB } from "@/store/common/breadcrumbs.module";
import { AUTH_MODULE } from "@/core/config/role.config";
import { RbacService } from "@/core/services/rbac.service";

export default {
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "BE" },
      { title: "Navigation menu BE" }
    ]);
  },
  computed: {
    allowedNavigation() {
      return this.navigation.where(x => this.allowedLinks(x.links).length > 0);
    }
  },
  methods: {
    allowedLinks(links) {
      const allowedLinks = [];
      links.forEach(link => {
        if (RbacService.isAllowed(link.roles)) allowedLinks.push(link);
      });
      return allowedLinks;
    }
  },
  data: () => ({
    navigation: [
      {
        header: "Dashboards",
        links: [
          {
            name: "Criticals",
            to: "dashboards/criticals",
            icon: "mdi-bell",
            roles: AUTH_MODULE.BE_CRITICALS
          }
        ],
        flexmd: 4,
        flexsm: 6
      },
      {
        header: "Reporting",
        links: [
          {
            name: "Availability Report",
            to: "reporting/availability-report",
            icon: "mdi-file-outline",
            roles: AUTH_MODULE.BE_AVAILABILITY_REPORTS
          },
          {
            name: "Service Management Reports",
            to: "reporting/service-management-report",
            icon: "mdi-text-box-multiple-outline",
            roles: []
          }
        ],
        flexmd: 4,
        flexsm: 6
      }
    ]
  })
};
